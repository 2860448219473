









































































































































































































































































	.content {
		margin: 0.2rem 0;
		margin-bottom: 0;
		font-family: PingFangSC-Regular;
	}
.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 .2rem;
  
  .vanCol4PStyle {
    font-size: 0.28rem;
    text-align: right;
    margin-top: 0.25rem;
	span {
		  font-family: Bahnschrift;
	}
  }
  
  .bottomDivRowColStylePS {
    font-size: .28rem;
    line-height: .4rem;
    margin-top: .08rem;
  }
  
  .bottomDivRowColStyleP2 {
    font-size: 0.24rem;
    line-height: .35rem;
  }
  
}
.tabBox {
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	min-height: 91.8vh;
}
.commonTabtit {
		color: #373737;
		font-size: .24rem;
		height: .8rem;
		// border-bottom: 1px solid #EDEDED;
		margin: 0 0.2rem;
		margin-bottom: .2rem;
		.type {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 0.2rem;
			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				.txt {
					padding: 0.1rem 0;
					color: #8E96A3;
					font-size: 0.24rem;
				}
				.bottom_xian {
					width: 0.41rem;
					height: 0.06rem;
					background-color: #FFFFFF;
				}
				// margin-right: .5rem;
				.active {
					font-size: .28rem;
					font-weight: bold;
					color: #333333;
					// border-bottom: 3px solid #EFBA0D;
				}
				.active1 {
					background-color: #EFBA0D;
					// border-bottom: 3px solid #EFBA0D;
				}
			}
		}
	}
	.bottomDIvStyle {
		margin-top: 0.4rem;
	}
	.mainColor1 {
		color: #32BF88 !important;
	}
	.mainColor3 {
		color: #F5465D !important;
	}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;