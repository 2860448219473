.content {
  margin: 0.2rem 0;
  margin-bottom: 0;
  font-family: PingFangSC-Regular;
}
.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 0.2rem;
}
.vanRowBoderStyle .vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  margin-top: 0.25rem;
}
.vanRowBoderStyle .vanCol4PStyle span {
  font-family: Bahnschrift;
}
.vanRowBoderStyle .bottomDivRowColStylePS {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.08rem;
}
.vanRowBoderStyle .bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.tabBox {
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  min-height: 91.8vh;
}
.commonTabtit {
  color: #373737;
  font-size: 0.24rem;
  height: 0.8rem;
  margin: 0 0.2rem;
  margin-bottom: 0.2rem;
}
.commonTabtit .type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.2rem;
}
.commonTabtit .type li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.commonTabtit .type li .txt {
  padding: 0.1rem 0;
  color: #8E96A3;
  font-size: 0.24rem;
}
.commonTabtit .type li .bottom_xian {
  width: 0.41rem;
  height: 0.06rem;
  background-color: #FFFFFF;
}
.commonTabtit .type li .active {
  font-size: 0.28rem;
  font-weight: bold;
  color: #333333;
}
.commonTabtit .type li .active1 {
  background-color: #EFBA0D;
}
.bottomDIvStyle {
  margin-top: 0.4rem;
}
.mainColor1 {
  color: #32BF88 !important;
}
.mainColor3 {
  color: #F5465D !important;
}
